import type { Webmaster } from 'types';

import { useMemo } from 'react';
import Head from 'next/head';

const VerificationMeta: React.FC<Webmaster> = props => {
  const metaValues = useMemo(() => {
    return [
      { name: 'baidu-site-verification', content: props?.baiduVerification },
      { name: 'msvalidate.01', content: props?.bingVerification },
      {
        name: 'google-site-verification',
        content: props?.googleVerification,
      },
      {
        name: 'p:domain_verify',
        content: props?.pinterestVerification,
      },
      { name: 'yandex-verification', content: props?.yandexVerification },
    ];
  }, [props]);

  return (
    <>
      {metaValues.map(
        ({ name, content }) =>
          content && <meta name={name} content={content} key={name} />,
      )}
    </>
  );
};

const AppHead: React.FC<{ webmasterMeta?: Webmaster }> = ({
  webmasterMeta,
}) => {
  return (
    <Head>
      {webmasterMeta && <VerificationMeta {...webmasterMeta} />}
      <meta httpEquiv="x-ua-compatible" content="ie=edge" key="httpequiv" />
      <meta
        name="viewport"
        content="width=device-width, viewport-fit=cover, initial-scale=1.0, maximum-scale=3.0"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#36DC90"
      />
      <link rel="shortcut icon" href="/favicon/favicon.ico" />
      <meta name="theme-color" content="#ffffff" key="theme-color" />
      {process.env.NODE_ENV === 'production' ? (
        <script
          id="reddit-ad-script"
          dangerouslySetInnerHTML={{
            __html: `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_uxq3xpdi');rdt('track', 'PageVisit');`,
          }}
        />
      ) : null}
      {process.env.NODE_ENV === 'production' ? (
        <script
          id="jointcommerce-event-sitewide-pixel"
          dangerouslySetInnerHTML={{
            __html: `var pixelLoaderScript=document.createElement("script");
              pixelLoaderScript.src="https://app.jointcommerce.com/api/pixel-loader.js?pixel_id=610&event_type=tracking";
              document.head.appendChild(pixelLoaderScript);`,
          }}
        />
      ) : null}
      {process.env.NODE_ENV === 'production' ? (
        <>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-13L6MSSZMY"
          />
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=AW-10889710135"
          />
          <script
            id="google-tag-init"
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-13L6MSSZMY');
                gtag('config', 'AW-10889710135');`,
            }}
          />
          <script
            id="google-ads-conversion-tracking"
            dangerouslySetInnerHTML={{
              __html: `function gtag_report_conversion() {
                gtag('event', 'conversion', {
                    'send_to': 'AW-10889710135/ODaCCO6PxZcaELeUz8go'
                });
                return false;
              }`,
            }}
          />
        </>
      ) : null}
    </Head>
  );
};

export default AppHead;
